<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">ダッシュボード</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-body">
            <a-form layout="inline">
              対象支店：
              <a-select
                show-search
                :value="branchId"
                style="width: 30%;"
                @change="targetBranchChange"
                :filter-option="filterOption"
              >
                <a-select-option
                  key="0"
                  value="0"
                  selected
                >
                  全て
                </a-select-option>
                <a-select-option
                  v-for="item in targetBranchOptions"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form>
          </div>
        </section>
      </div>
      <div class="col-lg-6">
        <section class="card">
          <div class="card-header">
            <h2>車検終了日が近い車両（3ヶ月以内）</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingInspection" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingInspection">
              <a-table
                :columns="columnsInspectionVehicle"
                :data-source="inspectionVehicles"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="name" slot-scope="id, record">
                  <router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ record.name }}<span v-if="concatVehicleNameAndNo">,{{ record.number }}</span></router-link>
                </span>
                <span slot="inspection_expiration_date" slot-scope="inspection_expiration_date">{{ inspection_expiration_date | momentDate }}</span>
              </a-table>
            </div>
          </div>
        </section>

        <section class="card">
          <div class="card-header">
            <h2>次回法定点検日が近い車両（3ヶ月以内）</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingLegalInspection" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingLegalInspection">
              <a-table
                :columns="columnsLegalInspectionVehicle"
                :data-source="legalInspectionVehicles"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="name" slot-scope="id, record">
                  <router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ record.name }}<span v-if="concatVehicleNameAndNo">,{{ record.number }}</span></router-link>
                </span>
                <span slot="legal_inspection_date" slot-scope="legal_inspection_date">{{ legal_inspection_date | momentDate }}</span>
              </a-table>
            </div>
          </div>
        </section>

        <section class="card">
          <div class="card-header">
            <h2>任意保険満了日が近い車両（3ヶ月以内）</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingInsuranceExpirationDate" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingInsuranceExpirationDate">
              <a-table
                :columns="columnsInsuranceExpirationDate"
                :data-source="insuranceExpirationVehicles"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="name" slot-scope="id, record">
                  <router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ record.name }}<span v-if="concatVehicleNameAndNo">,{{ record.number }}</span></router-link>
                </span>
                <span slot="insurance_expiration_date" slot-scope="insurance_expiration_date">{{ insurance_expiration_date | momentDate }}</span>
              </a-table>
            </div>
          </div>
        </section>
      </div>

      <div class="col-lg-6">
        <section class="card">
          <div class="card-header">
            <h2>免許更新が近いユーザー（1ヶ月以内）</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingLisence" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingLisence">
              <a-table
                :columns="columnsUser"
                :data-source="users"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="user_name" slot-scope="id, record">
                  <router-link :to="{ name: 'userShow', params: { id: record.user_id }}">{{ record.user_name }}</router-link>
                </span>
                <span slot="date_expiration" slot-scope="date_expiration">{{ date_expiration | momentDate }}</span>
              </a-table>
            </div>
          </div>
        </section>

        <section class="card">
          <div class="card-header">
            <h2>リース満了日が近い車両（3ヶ月以内）</h2>
          </div>
          <div class="card-body">
            <div v-if="loadingLeaseExpiration" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loadingLeaseExpiration">
              <a-table
                :columns="columnsLeaseExpirationVehicle"
                :data-source="leaseExpirationVehicles"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe1 :  $style.stripe0"
              >
                <span slot="name" slot-scope="id, record">
                  <router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ record.name }}<span v-if="concatVehicleNameAndNo">,{{ record.number }}</span></router-link>
                </span>
                <span slot="lease_expiration_date" slot-scope="lease_expiration_date">{{ lease_expiration_date | momentDate }}</span>
              </a-table>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      branchId: '0',

      loadingInspection: true,
      inspectionVehicles: [],
      columnsInspectionVehicle: [
        { title: '車両名', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '更新日', dataIndex: 'inspection_expiration_date', scopedSlots: { customRender: 'inspection_expiration_date' } },
      ],

      loadingLegalInspection: true,
      legalInspectionVehicles: [],
      columnsLegalInspectionVehicle: [
        { title: '車両名', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '更新日', dataIndex: 'legal_inspection_date', scopedSlots: { customRender: 'legal_inspection_date' } },
      ],

      loadingLeaseExpiration: true,
      leaseExpirationVehicles: [],
      columnsLeaseExpirationVehicle: [
        { title: '車両名', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '更新日', dataIndex: 'lease_expiration_date', scopedSlots: { customRender: 'lease_expiration_date' } },
      ],

      loadingLisence: true,
      users: [],
      columnsUser: [
        { title: '名前', dataIndex: 'user_name', scopedSlots: { customRender: 'user_name' } },
        { title: '更新日', dataIndex: 'date_expiration', scopedSlots: { customRender: 'date_expiration' } },
      ],

      loadingInsuranceExpirationDate: true,
      insuranceExpirationVehicles: [],
      columnsInsuranceExpirationDate: [
        { title: '車両名', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '満了日', dataIndex: 'insurance_expiration_date', scopedSlots: { customRender: 'insurance_expiration_date' } },
      ],

      targetBranchOptions: [],
    }
  },
  beforeCreate() {
    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      this.concatVehicleNameAndNo = response.concat_vehicle_name_and_no
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })
  },
  created() {
    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      this.targetBranchOptions = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })
  },
  beforeMount() {
    this.refleshInspectionVehicles()
    this.refleshLegalInspectionVehicles()
    this.refleshLeaseExpirationVehicles()
    this.refleshInsuranceExpirationVehicles()
    this.refleshUsers()
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    refleshInspectionVehicles() {
      this.loadingInspection = true
      const resultInspection = Vue.prototype.$api.send('get', 'vehicles/' + this.branchId + '/inspection_soon')
      resultInspection.then(response => {
        this.inspectionVehicles = response
        this.loadingInspection = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車検終了日が近い車両の取得に失敗しました。',
          })
          this.loadingInspection = false
        })
    },
    refleshLegalInspectionVehicles() {
      this.loadingLegalInspection = true
      const resultLegalInspection = Vue.prototype.$api.send('get', 'vehicles/' + this.branchId + '/legal_inspection_soon')
      resultLegalInspection.then(response => {
        this.legalInspectionVehicles = response
        this.loadingLegalInspection = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 次回法定点検日が近い車両の取得に失敗しました。',
          })
          this.loadingLegalInspection = false
        })
    },
    refleshLeaseExpirationVehicles() {
      this.loadingLeaseExpiration = true
      const resultLeaseExpiration = Vue.prototype.$api.send('get', 'vehicles/' + this.branchId + '/lease_expiration_soon')
      resultLeaseExpiration.then(response => {
        this.leaseExpirationVehicles = response
        this.loadingLeaseExpiration = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車検終了日が近い車両の取得に失敗しました。',
          })
          this.loadingLeaseExpiration = false
        })
    },
    refleshInsuranceExpirationVehicles() {
      this.loadingInsuranceExpirationDate = true
      const resultInsuranceExpiration = Vue.prototype.$api.send('get', 'vehicles/' + this.branchId + '/insurance_expiration_soon')
      resultInsuranceExpiration.then(response => {
        this.insuranceExpirationVehicles = response
        this.loadingInsuranceExpirationDate = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 任意保険満了日が近い車両の取得に失敗しました。',
          })
          this.loadingInsuranceExpirationDate = false
        })
    },
    refleshUsers() {
      this.loadingLisence = true
      const resultExpired = Vue.prototype.$api.send('get', 'users/' + this.branchId + '/expired_soon')
      resultExpired.then(response => {
        this.loadingLisence = false
        this.users = response
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 免許更新が近いユーザーの取得に失敗しました。',
          })
          this.loadingLisence = false
        })
    },
    targetBranchChange(val) {
      this.branchId = val
      this.refleshInspectionVehicles()
      this.refleshLegalInspectionVehicles()
      this.refleshLeaseExpirationVehicles()
      this.refleshInsuranceExpirationVehicles()
      this.refleshUsers()
    },
  },
  filters: {
    momentDate: function (date) { return moment(date).format('YYYY/MM/DD') },
    momentTime: function (time) { return moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') },
  },
}
</script>
<style lang="scss" module>
@import 'style.module.scss';
</style>
<style>
.list-name {
  font-size: 1.5rem;
}
</style>
